














































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
// import { Store } from 'vuex';
// import { readUserProfile } from '@/store/main/getters';
import { readUserItemsPending } from '@/store/main/getters';
import { dispatchGetUserItemsPending } from '@/store/main/actions';
import { readItemCreateError } from '@/store/main/getters';
import { dispatchItemCreate } from '@/store/main/actions';
import { readHasAdminAccess } from '@/store/main/getters';
import { readConstants } from '@/store/main/getters';
import { readUserProfile } from '@/store/main/getters';
import { dispatchGetConstants } from '@/store/main/actions';
import { dispatchItemCancelPurchase } from '@/store/main/actions';
import { readIsLoggedIn } from '@/store/main/getters';


@Component
export default class Dashboard extends Vue {
  public dialogCancel = false;
  public cancelItemId = -1;

  // my edit
  // public plans = ['新台幣新手方案', '新台幣穩定方案', 'USDT 穩定方案'];
  // public plansCode = ['TWD_newbie', 'TWD_stable', 'USDT_stable'];
  // public plans = ['USDT 穩定方案', 'USDT 高利率方案'];
  // public plansCode = ['USDT_stable', 'USDT_high'];

  // public title: string = 'USDT 穩定方案';
  // public tokens: string = '';
  // public duration: string = '';
  // public contract: boolean = false;
  // public tokenType: string = 'TWD';
  // public placeholder: string = '金額';

  // public valid = false;

  // public amountMin = 1000;
  // public amountMax = 120000;
  // public durationMin = 14;
  // public durationMax = 180;

  public titleRules = [
    (v) => !!v || '請選擇方案',
  ];

  get windowWidth() {
    // return window.innerWidth;
    return 140;
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public async submitItemCancel() {
    dispatchItemCancelPurchase(this.$store, {itemId: this.cancelItemId});
    this.dialogCancel = false;
  }

  // public contractURL() {
  //   if (this.title == "USDT 高利率方案") {
  //     return "https://drive.google.com/file/d/1SA4Hy2vp1JaJlbbQZq0ZujCsMsZUECH_/view?usp=sharing";
  //   } else { // USDT 穩定方案
  //     return "https://drive.google.com/file/d/1SA4Hy2vp1JaJlbbQZq0ZujCsMsZUECH_/view?usp=sharing";
  //   }
  // }

  // public amountRules() {
  //   let minStr;
  //   let maxStr;

  //   if (this.tokenType === 'TWD') {
  //     minStr = '金額需大於 NT$' + this.amountMin;
  //     maxStr = '金額需小於 NT$' + this.amountMax;
  //   } else if (this.tokenType === 'USDT') {
  //     minStr = '金額需大於 ' + this.amountMin + ' USDT';
  //     maxStr = '金額需小於 ' + this.amountMax + ' USDT';
  //   } else {
  //     minStr = '金額需大於 ' + this.amountMin;
  //     maxStr = '金額需小於 ' + this.amountMax;
  //   }

  //   return [
  //     (v) => !!v || '請決定金額',
  //     (v) => ( v && v >= this.amountMin ) || minStr,
  //     (v) => ( v && v <= this.amountMax ) || maxStr,
  //   ];
  // }

  // public durationRules() {
  //   return [
  //     (v) => !!v || '請決定約期',
  //     (v) => ( v && v >= this.durationMin ) || '約期需大於 ' + this.durationMin + ' 天',
  //     (v) => ( v && v <= this.durationMax ) || '約期需小於 ' + this.durationMax + ' 天',
  //   ];
  // }

  // public contractRules() {
  //   return [v => !!v || '請閱讀並同意定型化契約書']
  // }

  // public get itemCreateError() {
  //   return readItemCreateError(this.$store);
  // }

  // public changedValue(value) {
  //     // choose coin type to display
  //     // plans = ['USDT 穩定方案'];
  //     if (value === this.plans[0]) {
  //         this.placeholder = 'USDT 數量';
  //         this.amountMin = 1000;
  //         this.amountMax = 1000000;
  //         this.durationMin = 90;
  //         this.durationMax = 365;
  //         this.tokenType = 'USDT';
  //     } else if (value === this.plans[1]) {
  //         this.placeholder = 'USDT 數量';
  //         this.amountMin = 1000;
  //         this.amountMax = 1000000;
  //         this.durationMin = 90;
  //         this.durationMax = 365;
  //         this.tokenType = 'USDT';
  //     }
      
  //     // if (value === this.plans[0]) {
  //     //     this.placeholder = 'TWD 數量';
  //     //     this.amountMin = 1000;
  //     //     this.amountMax = 50000;
  //     //     this.durationMin = 14;
  //     //     this.durationMax = 180;
  //     //     this.tokenType = 'TWD';        
  //     // } else if (value === this.plans[1]) {
  //     //     this.placeholder = 'TWD 數量';
  //     //     this.amountMin = 10000;
  //     //     this.amountMax = 200000;
  //     //     this.durationMin = 90;
  //     //     this.durationMax = 180;
  //     //     this.tokenType = 'TWD';
  //     // } else if (value === this.plans[2]) {
  //     //     this.placeholder = 'USDT 數量';
  //     //     this.amountMin = 1000;
  //     //     this.amountMax = 10000;
  //     //     this.durationMin = 90;
  //     //     this.durationMax = 180;
  //     //     this.tokenType = 'USDT';
  //     // }
  // }

  // // public async submit() {
  // //   if ((this.$refs.form as any).validate()) {
  // //     let submitTitle; // string
  // //     let submitCoin; // string, e.g. 'USDT:3000'
  // //     let submitDuration; // int

  // //     // parse title and coin
  // //     if (this.title === this.plans[0]) {
  // //         submitTitle = this.plansCode[0]; 
  // //         submitCoin = 'USDT:' + this.tokens;
  // //     } else if (this.title === this.plans[1]) {
  // //         submitTitle = this.plansCode[1]; 
  // //         submitCoin = 'USDT:' + this.tokens;
  // //     } else {
  // //         submitTitle = 'undefined_plan_title';
  // //         submitCoin = 'UNK:' + this.tokens;
  // //     }

  // //     // parse duration
  // //     submitDuration = this.duration.replace(' 天', '');
  // //     submitDuration = parseInt(submitDuration, 10);

  // //     // this.dialog = true;
  // //     dispatchItemCreate(this.$store, {title: submitTitle, initial_coins: submitCoin, duration: submitDuration});
  // //   }
  // // }

  // // parse URL query
  // // from main/purchase?plan=usdt_stable
  // created() {
  //   let params = (new URL(document.location.href).searchParams);
  //   let planTitle = params.get("plan");

  //   for (let i = 0; i < this.plans.length; i++) {
  //     if (planTitle === this.plansCode[i].toLowerCase()) {
  //       this.title = this.plans[i];
  //       break
  //     }
  //   }

  //   this.changedValue(this.title);

  //   window.addEventListener('resize', this.onResize);
  //   this.onResize();
  //   this.checkVersion();
  // }

  get userItemsPending() {
    return readUserItemsPending(this.$store);
  }

  get loggedIn() {
    return readIsLoggedIn(this.$store);
  }

  async mounted() {
    await dispatchGetUserItemsPending(this.$store);
    await dispatchGetConstants(this.$store);
  }

  // update version
  checkVersion() {
    const constants = readConstants(this.$store);

    let onlineVersion = "none";
    if (constants) {
      onlineVersion = constants.version;
    }

    let localVersion = localStorage.getItem('vers');
    let updateSignal = localStorage.getItem('upt');

    // check if we need to fresh the browser
    if (onlineVersion != "none" && localVersion != onlineVersion) { // detect newer online version
      localStorage.setItem('vers', onlineVersion);
      window.location.reload();
    } else if (updateSignal == 'refresh') { // detect refresh signal
      localStorage.setItem('upt', 'no');
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }

  onResize() {
    this.isMobile = window.innerWidth < 960;
  }
}

